body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, table, tr {
  background-color: #333 !important;
  color: #e6e6e6 !important;
}

.form-select {
  background-color: #474747 !important;
  color: #e6e6e6 !important;
}

.horizontal-scroll {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.horizontal-scroll>.row {
  width: 2000px;
}

.size-60 {
  size: 60px;
  font-size: 60px;
}

g>text {
  /* font-size:  !important; */
  color: #e6e6e6 !important;
  fill: #e6e6e6 !important;
}